import React from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import { startCase, orderBy } from 'lodash'
import { graphql, StaticQuery } from 'gatsby'
import { Button, Input, Icon, Col, Row, notification, Affix, Modal } from 'antd'

class TalentRoll extends React.Component {
  state = { limit: 60, skip: 0, term: null, viewProfile: null };
  handleAddToList = ({id, frontmatter = {}}) => {
    this.props.onSelect({
      id,
      fullName: frontmatter.title,
      thumbnail: frontmatter.thumbnail,
    });
    notification.open({
      placement: 'bottomLeft',
      message: 'List updated',
      description: `${startCase(frontmatter.title)} was added to your list`,
    });
  }
  applySearch = item => {
    const { term } = this.state;
    if (!term) return true;
    return JSON.stringify(item).includes(term);
  }
  onSearch = evt => {
    this.setState({ term: evt.target.value, skip: 0 });
  }
  onResetSearch = () => {
    this.setState({ term: null, skip: 0 });
  }
  handleMakeProfile = () => {
    const { term } = this.state;
    this.props.onSelect({
      id: v4(),
      fullName: term,
      thumbnail: {},
    });
    notification.open({
      placement: 'bottomLeft',
      message: 'List updated',
      description: `${startCase(term)} was added to your list`,
    });
    this.setState({ term: null, skip: 0 });
  }
  setCurrentPage = (current) => {
    window.scrollTo({ top: 0 })
    this.setState({ current });
  }
  setViewProfile = (profile) => {
    this.setState({ viewProfile: profile })
  }
  renderGroup = ({ node: post }) => {
    const { isSelected } = this.props;
    return (
      <div className="column is-3" key={post.id}>
        <article className="tile is-child" style={{
          overflow: 'hidden',
          backgroundColor: '#FFFFFF',
          boxShadow: isSelected(post) ? null : '0 0 8px rgba(1,1,1,.05)',
          opacity: isSelected(post) ? 0.4 : 1
        }}>
          <div
            className="title is-size-5"
            // to={post.fields.slug}
            style={{
              margin: '-8px -8px 0 -8px',
              display: 'block',
              height: 124,
              backgroundImage: `url(${post.frontmatter.thumbnail.childImageSharp.fluid.src})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}>
          </div>
          <div style={{ padding: '16px 16px' }}>
            <p>
              <div
                className="has-text-black is-size-6"
                style={{display: 'block', color: '#111'}}
                to={post.fields.slug}
              >
                {post.frontmatter.isTrending && <Icon type="line-chart" style={{ color: '#f07' }} />} {post.frontmatter.title}
              </div>
            </p>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
              <Button
                style={{marginTop: 20}}
                onClick={() => this.setViewProfile(post)}
                type="link"
              >
                View Profile
              </Button>
              <Button
                style={{marginTop: 20}}
                icon="plus-circle"
                onClick={() => this.handleAddToList(post)}
                disabled={isSelected(post)}
                type="danger"
              >
                Add to list
              </Button>
            </div>
          </div>
        </article>
      </div>
    )
  }
  render() {
    const { term, current = 'A', viewProfile } = this.state
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    let enhancedPosts;

    if(term) {
      enhancedPosts = posts.filter(this.applySearch);
    } else {
      enhancedPosts = (posts.filter(this.applySearch).reduce((ac, current) => {
        const char = current.node.frontmatter.title[0];
        ac[char] = [...ac[char] || [], current];
        return ac;
      }, {})[current] || []);
    }

    return (
      <div>
        <Row style={{marginBottom: 16}} type="flex" justify="space-around" align="middle">
          <Col span={16}>
            <h4 className="is-size-4">
              <Icon type="line-chart" style={{ color: '#f07' }} /> Trending Talent
            </h4>
          </Col>
          <Col span={8}>
            <Input.Search
              placeholder="Find someone..."
              onChange={this.onSearch}
              value={term}
            />
          </Col>
        </Row>
        <div>
          {posts && (
            <div>
              <Affix offsetTop={60}>
                <div style = {{ marginBottom: 32, marginTop: 32, display: term ? 'none' : 'block', background: '#fff' }}>
                  {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map(ch =>
                      <Button style={{
                        padding: current === ch ? '0 7px': '0 8px',
                        marginRight: 12,
                        color: current === ch ? '#f07': 'black',
                        border: current === ch ? '1px solid #f07': '1px solid white',
                        transform: current === ch ? 'scale(1.25)': 'none',
                      }} onClick={() => this.setCurrentPage(ch)}>{ch}</Button>
                    )
                  }
                </div>
              </Affix>
              <div className="columns is-multiline">
                {orderBy(enhancedPosts, ['node.frontmatter.title'], ['asc']).map(this.renderGroup)}
              </div>
            </div>
          )}
        </div>
        {!enhancedPosts.length && term && (
          <div style={{ padding: '100px 0', textAlign: 'center', color: '#929292' }}>
            <p>Sorry, we cannot find "<b><i>{term}</i></b>"</p>
            <p style={{marginTop: 16}}>
              <Button style={{ color: '#f07' }} onClick={this.handleMakeProfile}>
                Add "<b><i>{term}</i></b>" to your list
              </Button>
            </p>
          </div>
        )}
        {!enhancedPosts.length && !term && (
          <div style={{ padding: '100px 0', textAlign: 'center', color: '#929292' }}>
            <p>Sorry, we don't have any names starting with "<b><i>{current}</i></b>"</p>
          </div>
        )}
        {viewProfile && (
          <Modal
            visible={viewProfile}
            onOk={() => {
              this.handleAddToList(viewProfile);
              this.setState({viewProfile: null});
            }}
            onCancel={() => this.setState({viewProfile: null})}
          >
            <div
              className="title is-size-5"
              // to={post.fields.slug}
              style={{
                margin: '-8px -8px 22px -8px',
                display: 'block',
                height: 420,
                backgroundImage: `url(${viewProfile.frontmatter.thumbnail.childImageSharp.fluid.src})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}>
            </div>
            <h2>
              Name: {viewProfile.frontmatter.title}
            </h2>
            { viewProfile.frontmatter.links && 
              <div style={{ marginTop: 64 }}>
                Links:
                {
                  viewProfile.frontmatter.links.map(link => 
                    <p>
                      <a href={link.url} target="_blank">{link.label}</a>
                    </p>
                  )
                }
              </div>
            }
          </Modal>
        )}
      </div>
    )
  }
}

TalentRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  onSelect: PropTypes.func,
}

export default ({
  onSelect,
  isSelected,
}) => (
  <StaticQuery
    query={graphql`
      query TalentRollQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___title] }
          filter: { frontmatter: { templateKey: { eq: "talent-profile" }, visible: { eq: true } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 100)
              id
              fields {
                slug
              }
              frontmatter {
                thumbnail {
                  childImageSharp {
                    fluid(maxHeight: 300, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                isTrending
                visible
                links {
                  url
                  label
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <TalentRoll data={data} count={count} onSelect={onSelect} isSelected={isSelected} />}
  />
)
