import React from 'react'
import {Avatar, Icon, Button, Empty, Affix, Row, Col } from 'antd'
// import PropTypes from 'prop-types'
// import { v4 } from 'uuid'
// import { Link, graphql, StaticQuery } from 'gatsby'

function ListItem({profile, onRemove}) {
  return (
     <Row type="flex" justify="space-between" style={{ padding: '4px', marginBottom: 16 }}>
      <Col span="20">
        <Avatar shape="square" icon="user" />
        <span className="is-size-6" style={{padding: 16}}>{profile.fullName}</span>
      </Col>
      <Col span="4" style={{textAlign: 'right'}}>
        <Button icon="close-circle" onClick={() => onRemove(profile)}/>
      </Col>
    </Row>
  );
}

class MyList extends React.Component {
  render() {
    const {items, onRemove} = this.props;
    return (
      <Affix offsetTop={80}>
        <div className="columns is-vcentered" style={{padding: 16}}>
          <Icon type="folder-add" style={{fontSize: 24}}/>
          <div  style={{padding: '0 16px', margin: 0, lineHeight: 1}}>
            <h4 className="is-size-4">Your Projects</h4>
            {/* <h3 style={{ opacity: 0.7, fontSize: '85%' }}>(if possible in order of preference)</h3> */}
          </div>
        </div>
        <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
          {!items.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />  }
          {!!items.length && items.map(profile => <ListItem profile={profile} onRemove={onRemove} />)}
        </div>
      </Affix>
    )
  }
}

MyList.propTypes = {
  // items: []
  // onRemove: func
}

export default MyList;
